<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card border shadow-none">
          <div class="card-title p-3 rounded-top custom-page-header">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="bi bi-gear font-size-24"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 fw-normal">Account Info and Setting</small>
                </div>
              </div>
            </div>
            
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="mt-1">
                  
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <h5 class="font-size-14 mb-1 text-dark">Login Username</h5>
                        <p class="mb-3 text-purple"><span class="badge bg-soft-primary px-2 py-1 mt-1 font-size-14 rounded">{{username || '-'}}</span></p>
                      </div>
                      <div class="col-md-6 col-12 d-none">
                        <h5 class="font-size-14 mb-1 text-dark">Position</h5>
                        <p class="mb-3 text-purple"><span class="badge bg-soft-primary px-2 py-1 mt-1 font-size-14 rounded">{{formData.position}}</span> </p>
                      </div>
                    </div>
                  <form>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label>Name</label>
                            <input
                              v-model="formData.name"
                              :disabled="loading"
                              type="text"
                              class="form-control"
                              placeholder="Name"
                              name="name"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.name.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.formData.name.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.name.required"
                                >Name is required.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 d-none">
                          <div class="mb-3 position-relative">
                            <label>Full Name</label>
                            <input
                              v-model="formData.full_name"
                              type="text"
                              class="form-control"
                              placeholder="Name"
                              name="name"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.full_name.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.formData.full_name.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.full_name.required"
                                >Full Name is required.</span
                              >
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 d-none">
                          <div class="mb-3 position-relative">
                            <label>NRIC</label>
                            <input
                              :disabled="loading"
                              v-model="formData.nric"
                              type="text"
                              class="form-control"
                              v-mask="'######-##-####'"
                              placeholder="NRIC"
                              name="NRIC"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.nric.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.formData.nric.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.nric.required"
                                >NRIC is required.</span
                              >
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label>Email</label>
                            <input
                              :disabled="loading"
                              v-model="formData.email"
                              type="text"
                              class="form-control"
                              placeholder="Email"
                              name="Email"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.formData.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.formData.email.email"
                                >Please enter a valid email.</span
                              >
                            </div>
                          </div>
                        </div>
                     </div>
                    <div class="mb-3 position-relative">
                      <label>Current Password</label>
                      <input
                        :disabled="loading"
                        v-model="formData.current_password"
                        :type="passwordFieldType_current"
                        class="form-control"
                        placeholder="Current Password"
                        name="name"
                        :class="{
                          'is-invalid':
                            submitted && $v.formData.current_password.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.formData.current_password.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.current_password.required"
                          >Current password is required.</span
                        >
                      </div>
                      <div
                        id="eye_current"
                        class="user-select-none"
                        @click="switchVisibility_current()"
                      >
                        <i
                          :class="classicon_current"
                          id="classicon_current"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>New Password</label>
                          <div>
                            <input
                              :disabled="loading"
                              v-model="formData.password"
                              :type="passwordFieldType"
                              name="password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.password.$error,
                              }"
                              placeholder="New Password"
                            />
                            <div
                              v-if="submitted && $v.formData.password.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.password.required"
                                >New password is required.</span
                              >
                              <span v-if="!$v.formData.password.minLength"
                                >Password must be at least 8 characters.</span
                              >

                              <span v-if="!$v.formData.password.alpha">
                                Password must contain at least one number and
                                both lower and uppercase letters and special
                                characters. (e.g.: MercHant357#@, Admin12#@,
                                etc.)</span
                              >
                            </div>
                            <div
                              id="eye"
                              class="user-select-none"
                              @click="switchVisibility()"
                            >
                              <i
                                :class="classicon"
                                id="eyetype"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>Confirm Password</label>
                          <input
                            :disabled="loading"
                            v-model="formData.confirmPassword"
                            :type="passwordFieldType2"
                            name="confirmPassword"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                submitted && $v.formData.confirmPassword.$error,
                            }"
                            placeholder="Confirm Password"
                          />
                          <div
                            v-if="
                              submitted && $v.formData.confirmPassword.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.formData.confirmPassword.required"
                              >Confirm password is required.</span
                            >
                            <span
                              v-else-if="
                                !$v.formData.confirmPassword.sameAsPassword
                              "
                              >Confirm password does not match the new
                              password.</span
                            >
                          </div>
                          <div
                            id="eye2"
                            class="user-select-none"
                            @click="switchVisibility2()"
                          >
                            <i
                              :class="classicon2"
                              id="eyetype2"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <p class="text-muted font-size-13">
                          <i
                            class="
                              uil uil-exclamation-triangle
                              font-size-16
                              text-warning
                              me-2
                            "
                          ></i>
                          Password is Case Sensitive.
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-8  col-lg-6 mb-3 d-none">
                        <div class="d-flex align-items-center">
                          <label class="w-100 mb-3">Electronic Signature</label>
                          <a href="javascript:void(0)" v-if="electronicSignature" @click="electronicSignature = '', electronicSignatureFileName = '', electronicSignatureFile = '',deleteSignature = 1" class="btn p-0 text-nowrap text-primary me-2 mb-2 ms-auto-lg">
                            <i class="mdi mdi-trash-can-outline"></i> Delete 
                          </a>       
                        </div>
                        <div id="preview" class="mb-3 rounded-3 bg-light border-muted p-3 position-relative text-center" @click="currentImageSelectedFunc('front')">
                        <img v-if="electronicSignature" class="rounded img-fluid mb-2" :src="electronicSignature" />
                        <!-- <img v-else src="" @click="$refs.fileInput.click()"> -->
                        <div v-else class="d-flex align-items-center p-3 m-2">
                          <div class="text-center w-100">
                            <h4 class="fw-normal text-primary text-center">
                              <i class="uil uil-image-plus mb-3 display-1"></i>
                              <small class="font-size-14 d-block text-body">Upload Signature</small>
                            </h4>
                          </div>
                        </div>
                        <div class="position-relative text-center">

                          <image-Uploader :class="['input-group']" ref="fileInput" id="image-input" :maxWidth="1600" :maxHeight="1600" :quality="0.8" outputFormat="base64" :multiple="false" accept="image/png, image/gif, image/jpeg, image/jpg" :hidden="true" @onUpload="startFiles" @onComplete="viewAllFiles"></image-Uploader>
                          <div>
                            <button class="btn mb-2 ms-2 btn-outline-light bg-light text-muted overflow-hidden fileInput" v-if="electronicSignatureFileName">
                              <i class="mdi mdi-file text-primary"></i> {{electronicSignatureFileName}}
                            </button>
                          </div>
                          <button type="button" class="btn btn-primary mb-0">
                            <i class="uil uil-upload"></i> Upload Signature </button>
                        </div>
                      </div>
                    </div>

                   
                     
                    </div>
                    <div class="mb-3 d-flex">
                      <!-- <button type="button" @click="resetData" class="btn btn-light me-2" :disabled="loading || buttonLoading[1] ">
                      <i class="uil-redo me-1"></i> 
                      <span v-if="buttonLoading[1]"> Resetting..</span>
                      <span v-else>  Reset </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[1]" role="status" aria-hidden="true"></span>
                      </button> -->

                      <button type="button" @click="save" class="btn btn-primary me-2" :disabled="loading || buttonLoading[0]">
                      
                      <span v-if="buttonLoading[0]"> Updating...</span>
                      <span v-else>  Update Setting </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[0]" role="status" aria-hidden="true"></span>
                      </button>
                      
                     
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="''" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import SignaturePad from 'signature_pad';
import { required, minLength, sameAs, email, helpers } from "vuelidate/lib/validators";
import ImageUploader from "@/components/imageUploader";

const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
/**
 * Settings page
 */
 const PageTitle = "Settings"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    ImageUploader
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      passwordFieldType_current: "password",
      classicon_current: "uil uil-eye-slash",
      passwordFieldType: "password",
      classicon: "uil uil-eye-slash",
      passwordFieldType2: "password",
      classicon2: "uil uil-eye-slash",
      username: "",
      merchant_code: "",
      merchant_key: "",
      account_type:"",
      formData: {
        name:"",
        current_password: "",
        password: "",
        confirmPassword: "",
        full_name:"",
        nric:"",
        email:"",
        position:"",
        otp: "",
      },
      fileCompressing:false,
      signaturePad: null,
      currentImageSelected:"front",
      electronicSignatureFileName:"",
      electronicSignature: "",
      electronicSignatureFile: "",
      deleteSignature: 0,
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
      full_name: {
        required,
      },
      position: {
        required,
      },
      nric: {
        required,
      },
      email: {
        required,
        email
      },
      current_password: {
      },
      password: {
        alpha,
        minLength: minLength(8),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    await this.reload()
    this.getData();
    // this.Signature();
    // window.addEventListener('resize', this.resizeSignaturePad);
    // this.resizeSignaturePad();
    
  }, 
  created(){
   
  },
  methods:{
    startFiles() {
      this.fileCompressing = true;
    },
    currentImageSelectedFunc(index) {
      console.log("seleted" + index);
      this.currentImageSelected = index;
      if (this.currentImageSelected == 'front') {
        this.$refs.fileInput.trigger();
      } else if (this.currentImageSelected == 'back') {
        this.$refs.fileInput2.trigger();
      }
    },
    viewAllFiles(res) {
      this.fileCompressing = false;
      for (let n of res) {
        // this.ShakeCampaignBanner = n.img;
        const myFile = new File([this.DataURIToBlob(n.img)], n.name, {
          type: "image/jpeg",
        });
        if (this.currentImageSelected == 'front') {
          this.electronicSignature= n.img;
          this.electronicSignatureFile = myFile;
          this.electronicSignatureFileName = n.name
          console.log(myFile)
        } else if (this.currentImageSelected == 'back') {
          // this.formSuccessData.transactionReceipt2 = n.img;
          // this.formSuccessData.transactionReceiptFile2 = myFile;
          // this.formSuccessData.transactionReceiptFilename2 = n.name
          // console.log(myFile)
        }
      }
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
      return new Blob([ia], {
        type: mimeString
      });
    },

    Signature(){
      const canvas = this.$refs.signatureCanvas;
      this.signaturePad = new SignaturePad(canvas);

      //var canvas = this.$refs.signatureCanvas;
      // var signaturePad = new SignaturePad(canvas);

      //const signaturePad = new SignaturePad(canvas);

      // // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
      // signaturePad.toDataURL(); // save image as PNG
      // signaturePad.toDataURL("image/jpeg"); // save image as JPEG
      // signaturePad.toDataURL("image/jpeg", 0.5); // save image as JPEG with 0.5 image quality
      // signaturePad.toDataURL("image/svg+xml"); // save image as SVG data url

      // // Return svg string without converting to base64
      // signaturePad.toSVG(); // "<svg...</svg>"
      // signaturePad.toSVG({includeBackgroundColor: true}); // add background color to svg output

      // // Draws signature image from data URL (mostly uses https://mdn.io/drawImage under-the-hood)
      // // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
      // signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");

      // // Draws signature image from data URL and alters it with the given options
      // signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...", { ratio: 1, width: 400, height: 200, xOffset: 100, yOffset: 50 });

      // // Returns signature image as an array of point groups
      // const data = signaturePad.toData();

      // // Draws signature image from an array of point groups
      // signaturePad.fromData(data);

      // // Draws signature image from an array of point groups, without clearing your existing image (clear defaults to true if not provided)
      // signaturePad.fromData(data, { clear: false });

      // // Clears the canvas
      // signaturePad.clear();

      // // Returns true if canvas is empty, otherwise returns false
      // signaturePad.isEmpty();

      // // Unbinds all event handlers
      // signaturePad.off();

      // // Rebinds all event handlers
      // signaturePad.on();
    },
    clearSignature() {
      // this.electronicSignature = ""
      // this.electronicSignatureFile = ""
      this.signaturePad.clear();
    },
    saveSignature() {
      var signatureData = this.signaturePad
      console.log("signature"+signatureData)
      // Do something with the signature data
      if (!signatureData.isEmpty()){
        this.electronicSignature = signatureData.toDataURL();
        var base64 = signatureData.toDataURL()
        //console.log(base64)
        const myFile = new File([this.DataURIToBlob(base64)], 'signature', {
          type: "image/png",
        });
        this.electronicSignatureFile = myFile
        console.log(this.electronicSignatureFile)
      }
      this.signaturePad.clear();
      //console.log(this.documentation.electronicSignature )
    },

    resizeSignaturePad() {
      const canvas = this.$refs.signatureCanvas;
      const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      const parent = canvas.parentNode;
      const width = parent.offsetWidth;
      const height = Math.round(width * 1 / 2);
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      this.signaturePad.clear();
    },
     resetData() {
      this.submitted = false;
      this.formData.current_password = "";
      this.formData.password = "";
      this.formData.confirmPassword = "";
    },
    switchVisibility_current() {
      (this.classicon_current =
        this.classicon_current === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType_current =
          this.passwordFieldType_current === "password" ? "text" : "password");
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
     switchVisibility2() {
      (this.classicon2 =
        this.classicon2 === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password");
    },
    getMerchantData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        axios({
            method: "post",
            url:  appConfig.APIHost + 'controller/account/getDashboardData',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              //this.reload()
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
   },
   getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        axios({
            method: "post",
            url: appConfig.APIHost + "controller/user/getOwnSetting",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData.data)
              //this.reload()
              this.deleteSignature = 0
              this.formData.name = resData.data.name;
              this.formData.full_name = resData.data.full_name;
              this.formData.email = resData.data.email;
              this.formData.nric = resData.data.nric_no;
              this.formData.position = resData.data.position;
              this.electronicSignature = resData.data.signature;
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    save() {
        this.submitted = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
            //this.saveSignature() 
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[0] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername",  this.accessUsername);
            bodyFormData.append("name",  this.formData.name);
            bodyFormData.append("currentPassword",  this.formData.current_password);
            bodyFormData.append("password",  this.formData.password);
            bodyFormData.append("signatureImg", this.electronicSignatureFile);
            bodyFormData.append("deleteSignature", this.deleteSignature);
            bodyFormData.append("fullName", this.formData.full_name);
            bodyFormData.append("nric", this.formData.nric);
            bodyFormData.append("email", this.formData.email);

            const sendRequest = async () => {
              try {
                const response = await 
                axios.post(
                   appConfig.APIHost + 'controller/user/updateOwnSetting', 
                   bodyFormData, {
                   headers: { "Content-Type": "multipart/form-data" },
                });
                if(response){
                  var resData = response.data;
                  if (resData.status == 200) {
                    console.log(resData)
                    
                    const updateData = {
                        "name": this.formData.name,
                        "full_name": this.formData.full_name || '',
                        "nric": this.formData.nric_no || '',
                        "email": this.formData.email || '',
                    }
                    this.$refs.commonFunc.updateData(JSON.stringify(updateData))

                    if (this.formData.current_password !== "" && this.formData.password !==""){
                      Swal.fire({
                        icon: 'success',
                        title: 'Password and Setting Updated',
                        html: `Password have been successfully updated!`,
                        confirmButtonColor: '#FA6541',
                        confirmButtonText: this.$t('siteLang.Done')
                      })
                      .then(() => {
                        this.resetData();
                        this.reload();
                        this.$router.push({
                          path: "/logout",
                        });
                      })
                    }else{
                      Swal.fire({
                        icon: 'success',
                        title: 'Setting Updated',
                        html: `Setting have been successfully updated!`,
                        confirmButtonColor: '#FA6541',
                        confirmButtonText: this.$t('siteLang.Done')
                      }).then(() => {
                        this.resetData();
                        this.reload();
                        this.getData();
                      })
                    }
                  }
                  else if (resData.status == 440) {
                    console.log(resData)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                      localStorage.clear();
                      this.$router.push({
                          path: "/login",
                    });
                  }
                  else{
                    console.log(resData)
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      html: resData.message,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }
                  
                  this.loading = false;
                  this.submitted = false
                  this.buttonLoading[0] = false
                  this.$Progress.finish();
                }
                } catch (error) {
                  this.buttonLoading[0] = false
                  this.loading = false;
                  this.$Progress.finish();
                  //Swal.fire("Error", error, "error");
                  Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                }
            };

            sendRequest();
        }
    },
    
    reset() {
        this.submitted = true
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[1] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("phone",  this.accessPhone);
            axios({
                method: "post",
                url: appConfig.DemoAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response)=>{
                var resData = response.data;
                if (resData.status == 200) {
                  console.log(resData)
                  Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    html: `API ID and API Hash have been successfully deleted and reset!`,
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done')
                  })
                  const updateData = {
                    "apiID": 'YOUR_API_ID',
                    "apiHash": 'YOUR_API_HASH',
                 }
                 this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                 this.reload()
                   
                } 
                else if (resData.status == 440){
                  Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                    localStorage.clear();
                    this.$router.push({
                        path: "/login",
                  });
                }
                else {
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                }
                this.loading = false;
                this.submitted = false
                this.buttonLoading[1] = false
                this.$Progress.finish();
            })
            .catch((error)=> {
                this.buttonLoading[1] = false
                this.loading = false;
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.username = data.username;
          this.merchant_key = data.merchant_key;
          this.merchant_code = data.merchant_code;
          this.formData.name = data.name;
          this.formData.full_name = data.full_name;
          this.formData.nric = data.nric;
          this.formData.email = data.email;
          this.formData.position = data.position;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>
<style scoped>
#eye_current,
#eye,
#eye2,
#eye3{
  position: absolute;
  top: 32px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 30px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
}
</style>